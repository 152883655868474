import {mapGetters, mapMutations} from "vuex";


export default {
  name: 'thanks',
  computed: {
    ...mapGetters({
      popup: 'system/popup',
    })
  },
  methods: {
    ...mapMutations({
      changeContent: `system/POPUP_TEXT_CONTENT`,
    })
  },
  destroyed() {
    this.changeContent({title: '', text: ''});
  }
}
